import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
import Application from "../components/wearther/application";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Metatags title="Wearther | May 24 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-24.png" url="https://cssgrid31.brett.cool" pathname="/may-24" mdxType="Metatags" />
    <Application mdxType="Application" />
    <Footer date={24} prev={true} next={true} mdxType="Footer">
      <p>{`It’s looking cold rainy in Melbourne at the moments, I made a responsive weather app mockup, built with CSS Grid!`}</p>
      <p>{`There’s a notion that many tech startups and services are created to cater to chores that developer’s mum’s used to do for them, and so I say, why don’t we go further, and create apps that can dress us?`}</p>
      <p>{`I hate it when I don’t wear clothes for the weather, and I’m either too hot or cold.`}</p>
      <p>{`So enter Wearthere—a weather app that tells you the weather `}<em parentName="p">{`and also`}</em>{` what you should wear, so I don’t have to figure that second part out.`}</p>
      <p>{`At a misguided attempt at inclusivity, this mockup includes an example of a setting toggle for people who `}<em parentName="p">{`don’t`}</em>{` just wear shorts or jeans like me, but you may be able to tell that that’s all I wear.`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      